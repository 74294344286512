var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        { attrs: { cols: "12", md: "7" } },
        [
          _c(
            "v-select",
            _vm._b(
              {
                attrs: {
                  items: _vm.filteredFundingSources,
                  rules: [_vm.rules.required],
                  "item-text": "name",
                  "item-value": "id",
                  loading: _vm.loading,
                },
                on: { input: _vm.handleFSSelect },
                model: {
                  value: _vm.form.fundingSource,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "fundingSource", $$v)
                  },
                  expression: "form.fundingSource",
                },
              },
              "v-select",
              _vm.fsInputProps,
              false
            )
          ),
        ],
        1
      ),
      _vm.selectedFundingSource
        ? _c(
            "v-col",
            { attrs: { cols: "12", md: "5" } },
            [
              _vm.budgetCodeInputType === "dropdown"
                ? [
                    _c("BudgetCodeWithLocation", {
                      attrs: {
                        locationId: Number(_vm.currentTripRequest.locationId),
                        fundingSource: _vm.selectedFundingSource,
                        useInputProps: _vm.useInputProps,
                        disabled: _vm.$attrs.disabled,
                        "admin-only": false,
                      },
                      model: {
                        value: _vm.form.budgetCode,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "budgetCode", $$v)
                        },
                        expression: "form.budgetCode",
                      },
                    }),
                  ]
                : [
                    _c(
                      "v-text-field",
                      _vm._b(
                        {
                          attrs: {
                            label: "Budget Code",
                            readonly:
                              _vm.$attrs.readonly || !_vm.isBudgetCodeEditable,
                            disabled: _vm.$attrs.disabled,
                          },
                          model: {
                            value: _vm.form.budgetCode,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "budgetCode", $$v)
                            },
                            expression: "form.budgetCode",
                          },
                        },
                        "v-text-field",
                        _vm.bcInputProps,
                        false
                      )
                    ),
                  ],
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }